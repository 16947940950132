import React, { useRef, useState } from "react";
import { Link } from "gatsby"

import { Swiper, SwiperSlide } from "swiper/react";

import { navigate } from 'gatsby';

import Grid from '@mui/material/Grid';


import Breadcrumbs from '@mui/material/Breadcrumbs';

import "swiper/css";

import {Navigation, FreeMode, Pagination } from "swiper";
import Paper from '@mui/material/Paper';

import 'swiper/css/free-mode';


import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';

import { useTheme } from '@mui/material/styles';

import { MdOutlineSwipeLeft } from "react-icons/md/";
import { MdOutlineSwipeRight } from "react-icons/md";

import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const reviewImages = [
  {
    label: "Picture of Lee Childs, an author, in the context of his review of E.O. Chirovici's The Book of Mirrors ",
    text: 'Intelligent and sophisticated. Highly recommended. ',

    imgPath:
      '/images/reviews/lee-min.jpg',
  },
  {
    label: "Picture of the newspaper logo L'Express in the context of the newspaper's review of E.O. Chirovici's The Book of Mirrors ",
        text: 'Un croisement entre La Maitre des illusions de Donna Tart, et l’Ombre du vent, de Carlos Ruiz Zafon ',

    imgPath:
      '/images/reviews/express-min.png',
  },
  {
    label: "Picture of the newspaper logo Sette in the context of the newspaper's review of E.O. Chirovici's The Book of Mirrors ",
        text: 'Ho letto il libro di E.O. Chirovici con la sorpresa, l’ammirazione, et l’inqueietudine con cui lessi il primo Paul Auster. Questo e un romanzo avvincente, impeccabile, capitale.  ',

    imgPath:
      '/images/reviews/sette-min.png',
  }, 
   {
    label: "Picture of the newspaper logo Madame Figaro in the context of the newspaper's review of E.O. Chirovici's The Book of Mirrors ",
        text: 'Un thriller bien huile, dans les belles regles d’art.  ',

    imgPath:
      '/images/reviews/figaro-min.jpg',
  },
    {
    label: "Picture of Denis Schek, an author, in the context of his review of E.O. Chirovici's The Book of Mirrors ",
    text: 'Das ist Unterhaltungsliteratur auf hohem Niveau, im Thriller-Genre ein herausragendes Buch.  ',

    imgPath:
      '/images/reviews/denis-min.jpg',
  },
];








export default function App(props) {

  

  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = reviewImages.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };




  return (

      <>
  <Swiper
        className='bookPage'
        spaceBetween={30}
autoHeight={true}
                centeredSlides={false}



        grabCursor={true}
  
        resistance={false}  


initialSlide={1}
  onSlideChange={(swiperCore) => {
            const {
              activeIndex,
              snapIndex,
              previousIndex,
              realIndex,
            } = swiperCore;

window.scrollTo(0,0)


              if (swiperCore.realIndex === 2) {    navigate('/books/bad-blood');    };

              if (swiperCore.realIndex === 0) {    navigate('/books');    };





        }}



        pagination={{
          clickable: true,
        }}

        modules={[FreeMode, Pagination]}



      >



              <SwiperSlide  className=' '> </SwiperSlide>





             <SwiperSlide className='white switchToSLC books'> 





<div className='longPage'>




<span className='standardIntroductionText standardBookIntroductionText animate__animated'>


    <Breadcrumbs aria-label="breadcrumb">
        <Link className='breadcrumbLink' to="/">
          Home
        </Link>
          <Link className='breadcrumbLink' to="/books">
          Books
        </Link>
        <Link className='breadcrumbLink' to="/books/book-of-mirrors">
          Book of Mirrors
        </Link>




      </Breadcrumbs>



<h3  className=' animate__animated'>The Book of Mirrors</h3>

</span>


<Grid className='bookGrid' container spacing={2}>

  <Grid item md={4} xs={4}>


   <span className='bookGridSpan'>Fiction </span>


  </Grid>

  <Grid item md={4} xs={4}>


   <span className='bookGridSpan'>2017 </span>


  </Grid>
  <Grid item md={4} xs={4}>


   <span className='bookGridSpan'>Century </span>


  </Grid>









  </Grid>







<span className='standardIntroductionText animate__animated'>

<p className='animate__animated'>

When big-shot agent Peter Katz receives an unfinished manuscript entitled The Book of Mirrors, he is intrigued. 

<br/><br/>

The author, Richard Flynn is writing a memoir about his time at Princeton in the late 80s, documenting his relationship with the famous Professor Joseph Wieder. One night in 1987, Wieder was brutally murdered in his home and the case was never solved.

Peter Katz is hell-bent on getting to the bottom of what happened that night twenty-five years ago and is convinced the full manuscript will reveal who committed the violent crime. But other people’s recollections are dangerous weapons to play with, and this might be one memory that is best kept buried.

<br/><br/>

This is one of the <a target='_blank' href='https://en.wikipedia.org/wiki/List_of_literary_works_by_number_of_translations'> most widely-translated books</a> currently in print and a film, Sleeping Dogs, based on the book is set to star Russell Crowe.

</p>
</span>




  <Box className='reviewBox swiper-no-swiping' sx={{ maxWidth: 400, flexGrow: 1 }}>
  


<AutoPlaySwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {reviewImages.map((step, index) => (
          <div key={step.label}>
            {Math.abs(activeStep - index) <= 2 ? (

<div>
<span className='reviewText'> {step.text} </span>

<br/><br/>

              <Box
                component="img"
                sx={{
                  height: 140,
                  display: 'inline-block',
                  maxWidth: 190,
                  overflow: 'hidden',
                  width: '100%',
                }}
                src={step.imgPath}
                alt={step.label}
              />
          </div>

            )  : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>
      <MobileStepper
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        variant='progress'
    
      />
    </Box>



<div className='bookNavigationButtons'>


<Link className='bookpageButton Left' to='/books/gods-weapons-and-money'>

<MdOutlineSwipeLeft />

  Gods, Weapons and Money


</Link>

<Link className='bookpageButton Right' to='/books/bad-blood'>

Bad Blood

<MdOutlineSwipeRight />


</Link>


</div>






</div>




</SwiperSlide>




























      <SwiperSlide  className=''> </SwiperSlide>

</Swiper>
       </> 
            
  );
}